/* eslint-disable camelcase */
import React, {
  useEffect, useState, lazy, Suspense,
} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { fetchAccountData } from '../Store/Authentication/Thunks';
import Page404 from '../Components/Errors/Page404';
import Splash from '../Components/Loaders/MainLoader';
import '../normalize.css';
import SocketeerConnection from '../services/socketeerConnections';
import GeneralError from '../Components/Errors/GeneralError/GeneralError';
import { updateChatInfo, getLang } from '../Store/actions';
import analytics from '../services/analytics';
import config from '../services/config';
import { initTranslation } from '../services/translate';
import { ConnectOneSignal, removePreLoader } from '../utils/init';
import NewLayout from '../Components/Layout/MainLayout/NewLayout.container';
import AllChats from '../Pages/AllChats/index';
import History from '../Pages/History';
import './styles.css';
import { Socket, StateAllChats } from '../Interfaces/AllChats';
import { RootState } from '../Interfaces/Default';
import useLittenMessages from '../hooks/useLittenMessages';

const MyChats = lazy(() => import('../Pages/MyChats'));
const LittenMyChats = lazy(() => import('../Components/LittenMyChats'));

interface Props {
  updateChatInfo: () => void;
}

declare global {
  interface Window {
    location: Location;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const App = ({ updateChatInfo }: Props) => {
  const {
    featureLittenMyChatsLive778,
    featureIbbConversationsLitCrmTagsAutomaticosEcom1074: flagShouldShowTags,
    featureLiveCrmSyncAssignment,
  } = useFlags();

  useLittenMessages(featureLittenMyChatsLive778 as boolean);
  const chatInfo = useSelector((state: StateAllChats) => state.chatInfo);
  const auth = useSelector((state: RootState) => state.auth);

  const isAdmin = auth?.user?.permissions?.includes('admin');

  const [loading, setLoading] = useState(true);
  const [socketConnection, setSocketConnection] = useState<Socket>({
    sendMessage: () => 1,
    sendAction: () => 1,
  });
  const [error] = useState<boolean>(false);
  const dispatch = useDispatch();

  const showHistoryAndVisitorTab = (!featureLiveCrmSyncAssignment)
    || ((featureLiveCrmSyncAssignment as boolean) && isAdmin);
  const initialMethod = () => {
    const {
      urls: { CRM },
    } = config;
    if (auth && auth.account) {
      if (auth.account.hideLeads) window.location.href = `${CRM}/billing`;
      setSocketConnection(
        SocketeerConnection(chatInfo, dispatch, updateChatInfo, {
          account: auth.account,
          user: auth.user,
        }),
      );
      ConnectOneSignal(auth.account, auth.user);
      analytics.identify(auth.account, auth.user);
      initTranslation(auth.user.language);
      dispatch(getLang(auth.user.language));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      Object.keys(auth.account).length <= 0
      && Object.keys(auth.user).length <= 0
    ) {
      dispatch(fetchAccountData(flagShouldShowTags as boolean));
    } else {
      initialMethod();
      removePreLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  // eslint-disable-next-line react/jsx-filename-extension
  if (error) return <GeneralError />;
  if (loading) return <Splash />;

  return (
    <NewLayout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/live/active" />
        </Route>
        <Route exact path="/live/">
          <Redirect to="/live/active" />
        </Route>
        <Route path="/live/active" exact>
          <Redirect to="/live/active/available" />
        </Route>
        <Route path="/live/visitors" exact>
          <Redirect to="/live/visitors/available" />
        </Route>
        <Route path="/live/history" exact>
          {showHistoryAndVisitorTab ? (
            <History socket={socketConnection} />
          ) : (
            <Redirect to="/live/active" />
          )}
        </Route>
        <Route exact path="/live/visitors/:tab">
          {showHistoryAndVisitorTab ? (
            <AllChats socket={socketConnection} />
          ) : (
            <Redirect to="/live/active" />
          )}
        </Route>
        <Route exact path="/live/active/:tab">
          <Suspense fallback={<div>Loading...</div>}>
            {featureLittenMyChatsLive778 ? (
              <LittenMyChats />
            ) : (
              <MyChats socket={socketConnection} />
            )}
          </Suspense>
        </Route>
        <Route component={Page404} />
      </Switch>
    </NewLayout>
  );
};

export default connect(null, {
  updateChatInfo,
})(App);
