import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import config from '../services/config';

import App from './App';

const makeLittenUrl = (litUrl: string) => {
  let littenUrl = `${config.urls.LITTEN}`;

  if (litUrl.includes('live/active/available?')) {
    littenUrl += '/?activeTab=available';
  }

  if (litUrl.includes('live/active/mine?')) {
    littenUrl += '/?activeTab=taken';
  }

  if (litUrl.includes('?chatId=')) {
    littenUrl += `${littenUrl.includes('/?') ? '&' : '/?'}conversationId=${
      litUrl.split('chatId=')[1]
    }`;
  }

  return littenUrl;
};

const AppContainer = () => {
  const { featureLive1613: isIframeMode } = useFlags();

  useEffect(() => {
    // Si lit es un iframe, consulto por la url del padre (litten)
    const url = (window.location !== window.parent.location)
      ? document.referrer
      : document.location.href;

    // Si la url no es litten, entonces redirecciono
    if (!url.includes(config.urls.LITTEN) && isIframeMode) {
      // Armo la url por si viene del CRM
      window.location.replace(makeLittenUrl(window.location.href));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <App />;
};

export default AppContainer;
